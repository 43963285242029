<template>
  <div class="add-hospital">
    <h1>Add Hospital</h1>
    <AddHospital :customer="2" @submit="create" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import router from '@/router'
import AddHospital from "@/containers/Hospital/AddHospital";

export default {
  components: {
    AddHospital
  },
 methods: {
    ...mapActions(['addHospital']),
    create(hospital) {
     this.addHospital(hospital)
    }
  }
};
</script>

<style lang="scss" scoped>
.message {
  @media all and (min-width: 760px) {
    width: 50%;
    margin: auto;
  }
}
</style>
